import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import holidays from '../../../../constants/holidays';
import AuthContext from '../../../../contexts/auth-context';
import useHttp from '../../../../hooks/use-http';
import timeOptions from '../../../../utils/form-time-options';
import ConfirmPopUp from '../../../PopUps/ConfirmPopUp/ConfirmPopUp';
import FormControlWrap from '../../../Scheduler/Forms/FormControlWrap/FormControlWrap';
import Button from '../../../UI/Input/Button/Button';
import Checkbox from '../../../UI/Input/Checkbox';
import InputDate from '../../../UI/Input/InputDate';
import Select from '../../../UI/Input/Select/Select';
import Textarea from '../../../UI/Input/Textarea/Textarea';
import Loader from '../../../UI/Loader/Loader';
import Modal from '../../../UI/Modal/Modal';
import classes from './SocialAddRequestForm.module.css';

const adjDateFormat = (dateObj) => {
  let ISODateFormat;

  ISODateFormat = `${dateObj.getFullYear()}-${
    dateObj.getMonth() < 9
      ? `0${dateObj.getMonth() + 1}`
      : dateObj.getMonth() + 1
  }-${dateObj.getDate() < 10 ? `0${dateObj.getDate()}` : dateObj.getDate()}`;

  return ISODateFormat;
};

const DAYS_OF_WEEK_OPTIONS = [
  { optionVal: 'SUN', name: '일' },
  { optionVal: 'MON', name: '월' },
  { optionVal: 'TUE', name: '화' },
  { optionVal: 'WED', name: '수' },
  { optionVal: 'THU', name: '목' },
  { optionVal: 'FRI', name: '금' },
  { optionVal: 'SAT', name: '토' },
];

const YOIL = {
  MON: 'MON',
  TUE: 'TUE',
  WED: 'WED',
  THU: 'THU',
  FRI: 'FRI',
  SAT: 'SAT',
  SUN: 'SUN',
};

const SocialAddRequestForm = (props) => {
  const { title, socialDataType, onSetSocialAddRequestList, onClose } = props;
  const today = new Date();
  const authCtx = useContext(AuthContext);
  const params = useParams();
  const currentStadium = authCtx.stadiumsList.find(
    (stadium) => +stadium.id === +params.stadiumId
  );
  const [enteredStadiumZone, setEnteredStadiumZone] = useState(+params.zoneId);
  const [enteredIsManger, setEnteredIsManger] = useState(false);

  let initialMonth;
  let initialDate;
  if (socialDataType === 'INDIVIDUAL') {
    initialMonth = today.getMonth();
    initialDate = today.getDate();
  } else if (socialDataType === 'FIXED') {
    initialMonth = today.getMonth() + 2;
    initialDate = 1;
  }
  const [enteredDate, setEnteredDate] = useState(
    new Date(
      today.getFullYear(),
      initialMonth,
      initialDate,
      today.getHours(),
      0
    )
  );
  const defaultStartTime = enteredDate.toLocaleTimeString('ko-KR', {
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
  });
  const [enteredStartTime, setEnteredStartTime] = useState({
    optionVal: `${adjDateFormat(enteredDate)}T${defaultStartTime}`,
    optionTxt: defaultStartTime,
  });
  const [selectedDays, setSelectedDays] = useState(
    DAYS_OF_WEEK_OPTIONS[enteredDate.getDay()].optionVal
  );
  const [enteredMemo, setEnteredMemo] = useState('');
  const [userAgreementPopUp, setUserAgreementPopUp] = useState(false);
  const selectedDatetime = dayjs(enteredDate)
    .tz('Asia/Seoul')
    .format('YYYY-MM-DD');
  const isHoliday = holidays.includes(selectedDatetime);
  const { isLoading, sendRequest: sendSocialRequest } = useHttp();

  const changeStadiumZoneHandler = (evt) => {
    setEnteredStadiumZone(evt.target.value);
  };
  const changeMemoHandler = (evt) => {
    const value = evt.target.value;
    setEnteredMemo(value);
  };
  const changeIsMangerHandler = (evt) => {
    setEnteredIsManger(evt.target.checked);
  };
  const changeDateHandler = (evt) => {
    if (evt.target.value === '') {
      return;
    }

    let newDate;
    if (socialDataType === 'INDIVIDUAL') {
      newDate = new Date(evt.target.value);
    } else if (socialDataType === 'FIXED') {
      const splitDate = evt.target.value.split('-');
      newDate = new Date(splitDate[0], +splitDate[1] - 1, 1);
    }

    setEnteredDate(newDate);
    setEnteredStartTime((prevState) => {
      return {
        optionVal: `${evt.target.value}T${prevState.optionTxt}`,
        optionTxt: prevState.optionTxt,
      };
    });
  };
  const changeStartTimeHandler = (evt) => {
    setEnteredStartTime({
      optionVal: evt.target.value,
      optionTxt: evt.target.value.split('T')[1],
    });
  };
  const changeDaysHandler = (evt) => {
    setSelectedDays(evt.target.value);
  };
  const userAgreementPopUpOpenHandler = () => {
    setUserAgreementPopUp((prevState) => !prevState);
  };

  const submitHandler = (evt) => {
    evt.preventDefault();
    if (isLoading) {
      return;
    }

    if (enteredIsManger && !enteredMemo.trim()) {
      alert('매니저 가능 시 성함과 연락처를 입력해 주세요.');
      return;
    }

    const createSocialRequest = async (res) => {
      const data = await res.json();

      if (res.ok) {
        onClose();
        onSetSocialAddRequestList((prevState) => {
          return [...prevState, data];
        });
        return;
      }

      alert(data.message);
    };

    if (socialDataType === 'INDIVIDUAL') {
      sendSocialRequest(
        {
          urlPath: 'social-requests/individual/',
          method: 'POST',
          body: {
            request_type: 'ADD',
            zone_id: enteredStadiumZone,
            comment: enteredMemo,
            is_manager: enteredIsManger,
            start_date_time: enteredStartTime.optionVal,
          },
        },
        createSocialRequest
      );
    } else if (socialDataType === 'FIXED') {
      sendSocialRequest(
        {
          urlPath: 'social-requests/fixed/',
          method: 'POST',
          body: {
            request_type: 'ADD',
            zone_id: enteredStadiumZone,
            comment: enteredMemo,
            is_manager: enteredIsManger,
            startDate: enteredStartTime.optionVal.split('T')[0],
            startTime: enteredStartTime.optionVal.split('T')[1],
            yoil: YOIL[selectedDays],
          },
        },
        createSocialRequest
      );
    }
  };

  return (
    <Modal title={title} onClose={onClose} classList={['modalSmall']}>
      <form className={classes.AppointmentForm} onSubmit={submitHandler}>
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap>
            <label htmlFor='stadium-zone' className={classes.InputLabel}>
              구역
            </label>
            <Select
              selectType='object'
              attribute={{
                id: 'stadium-zone',
                value: enteredStadiumZone,
                onChange: changeStadiumZoneHandler,
              }}
              options={currentStadium.zones}
            />
          </FormControlWrap>
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap>
            <label
              htmlFor={socialDataType === 'FIXED' ? null : 'appointment-date'}
              className={classes.InputLabel}
            >
              {socialDataType === 'INDIVIDUAL' ? '일정' : '적용 월'}
            </label>
            <InputDate
              attribute={{
                id: 'appointment-date',
                value: enteredDate,
                tabIndex: socialDataType === 'FIXED' ? -1 : null,
                onChange: changeDateHandler,
              }}
              visibleText={socialDataType === 'FIXED' && 'month'}
              classList={socialDataType === 'FIXED' ? ['disabled'] : []}
            />
          </FormControlWrap>
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap>
            <label
              htmlFor='appointment-time'
              className={`${classes.InputLabel} ${classes.hidden}`}
            >
              시간
            </label>
            <Select
              selectType='object'
              attribute={{
                id: 'appointment-time',
                value: enteredStartTime.optionVal,
                onChange: changeStartTimeHandler,
              }}
              options={timeOptions('start_time', enteredDate)}
            />
          </FormControlWrap>
        </div>
        {socialDataType === 'FIXED' && (
          <div className={classes['AppointmentForm__Row']}>
            <FormControlWrap>
              <label htmlFor='stadium-zone' className={classes.InputLabel}>
                요일
              </label>
              <Select
                selectType='object'
                attribute={{
                  id: 'stadium-zone',
                  value: selectedDays,
                  onChange: changeDaysHandler,
                }}
                options={DAYS_OF_WEEK_OPTIONS}
              />
            </FormControlWrap>
          </div>
        )}

        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap>
            <label htmlFor='is-manager' className={classes.InputLabel}>
              매니저
            </label>
            <Checkbox
              attribute={{
                name: 'is-manager',
                onChange: changeIsMangerHandler,
                defaultChecked: enteredIsManger,
              }}
            />
          </FormControlWrap>
        </div>
        {enteredIsManger && (
          <div className={classes['AppointmentForm__Row']}>
            <FormControlWrap>
              <label
                htmlFor='is-manager'
                className={`${classes.InputLabel} ${classes.hidden}`}
              ></label>
              <div>매니저 가능 시 성함과 연락처를 입력해 주세요.</div>
            </FormControlWrap>
          </div>
        )}
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap classList={['align--flex-start']}>
            <label
              htmlFor='memo'
              className={`${classes.InputLabel} ${classes.textarea}`}
            >
              메모
            </label>
            <Textarea
              attribute={{
                id: 'memo',
                value: enteredMemo,
                onChange: changeMemoHandler,
                placeholder: '메모',
              }}
            />
          </FormControlWrap>
        </div>
        <div className={classes['AppointmentForm__Row']}>
          {isHoliday && socialDataType === 'INDIVIDUAL' ? (
            <Button
              attribute={{ type: 'button' }}
              onClick={userAgreementPopUpOpenHandler}
            >
              확인
            </Button>
          ) : (
            <Button attribute={{ type: 'submit' }}>확인</Button>
          )}
        </div>
      </form>
      {userAgreementPopUp && (
        <ConfirmPopUp
          paragraph={
            "선택된 일정은 연휴 추가 매치 프로모션의 일환으로서 정상적으로 진행된 매치에 한하여 \
              '참가자 캐시 매출'에서 '매니저 비용' 뺀 금액의 50% 비율이 구장에 정산됩니다."
          }
          onClose={userAgreementPopUpOpenHandler}
          onSubmit={submitHandler}
        />
      )}
      {isLoading && <Loader />}
    </Modal>
  );
};

export default SocialAddRequestForm;
