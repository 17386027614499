import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthContextProvider } from './contexts/auth-context';
import { PenaltyCountProvider } from './contexts/penalty-count';
import './styles/styles.css';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('ko');

Sentry.init({
  dsn: 'https://dd09e1bdcb1a5d8bfc6a4c9b238e7c07@o1316627.ingest.sentry.io/4506115444113408',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  // tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <AuthContextProvider>
    <PenaltyCountProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PenaltyCountProvider>
  </AuthContextProvider>,

  document.getElementById('root')
);
