const timeOptions = (type, enteredDate, startDate) => {
  let options = [];
  const optionDate = enteredDate
    .toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replaceAll('. ', '-')
    .slice(0, -1);

  if (type === 'start_time') {
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 12; j++) {
        const optionTime = `${i < 10 ? `0${i}` : i}:${
          j * 5 < 10 ? `0${j * 5}` : j * 5
        }`;
        options.push({
          optionVal: `${optionDate}T${optionTime}`,
          optionTxt: optionTime,
        });
      }
    }
    return options;
  }

  if (type === 'end_time') {
    for (let i = 0; i < 25; i++) {
      for (let j = 0; j < 12; j++) {
        if (+startDate.optionTxt.split(':')[0] + i < 24) {
          const optionTime = `${
            +startDate.optionTxt.split(':')[0] + i < 10
              ? `0${+startDate.optionTxt.split(':')[0] + i}`
              : +startDate.optionTxt.split(':')[0] + i
          }:${j * 5 < 10 ? `0${j * 5}` : j * 5}`;

          options.push({
            optionVal: `${startDate.optionVal.split('T')[0]}T${optionTime}`,
            optionTxt: optionTime,
          });
        }

        if (+startDate.optionTxt.split(':')[0] + i >= 24) {
          const optionTime = `${
            +startDate.optionTxt.split(':')[0] + i - 24 < 10
              ? `0${+startDate.optionTxt.split(':')[0] + i - 24}`
              : +startDate.optionTxt.split(':')[0] + i - 24
          }:${j * 5 < 10 ? `0${j * 5}` : j * 5}`;

          options.push({
            optionVal: `${new Date(
              startDate.optionVal.split('-')[0],
              +startDate.optionVal.split('-')[1] - 1,
              +startDate.optionVal.split('-')[2].split('T')[0] + 1
            )
              .toLocaleDateString('ko-KR', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })
              .replaceAll('. ', '-')
              .slice(0, -1)}T${optionTime}`,
            optionTxt: optionTime,
          });
        }
      }
    }

    if (startDate.optionTxt.split(':')[1] === '00') {
      return (options = options.slice(12, -11));
    } else if (startDate.optionTxt.split(':')[1] === '05') {
      return (options = options.slice(13, -12));
    } else if (startDate.optionTxt.split(':')[1] === '10') {
      return (options = options.slice(14, -13));
    } else if (startDate.optionTxt.split(':')[1] === '15') {
      return (options = options.slice(15, -14));
    } else if (startDate.optionTxt.split(':')[1] === '20') {
      return (options = options.slice(16, -15));
    } else if (startDate.optionTxt.split(':')[1] === '25') {
      return (options = options.slice(17, -16));
    } else if (startDate.optionTxt.split(':')[1] === '30') {
      return (options = options.slice(18, -17));
    } else if (startDate.optionTxt.split(':')[1] === '35') {
      return (options = options.slice(19, -18));
    } else if (startDate.optionTxt.split(':')[1] === '40') {
      return (options = options.slice(20, -19));
    } else if (startDate.optionTxt.split(':')[1] === '45') {
      return (options = options.slice(21, -20));
    } else if (startDate.optionTxt.split(':')[1] === '50') {
      return (options = options.slice(22, -21));
    } else if (startDate.optionTxt.split(':')[1] === '55') {
      return (options = options.slice(23));
    }
  }
};

export default timeOptions;
