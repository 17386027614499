const holidays = [
  '2025-01-25',
  '2025-01-26',
  '2025-01-27',
  '2025-01-28',
  '2025-01-29',
  '2025-01-30',
  '2025-10-03',
  '2025-10-04',
  '2025-10-05',
  '2025-10-06',
  '2025-10-07',
  '2025-10-08',
  '2025-10-09',
  '2026-02-14',
  '2026-02-15',
  '2026-02-16',
  '2026-02-17',
  '2026-02-18',
  '2026-09-24',
  '2026-09-25',
  '2026-09-26',
  '2026-09-27',
  '2027-02-06',
  '2027-02-07',
  '2027-02-08',
  '2027-09-14',
  '2027-09-15',
  '2027-09-16',
  '2027-09-16',
  '2027-09-16',
  '2027-09-16',
];

export default holidays;
