import { useContext, useRef, useState } from 'react';
import AuthContext from '../../../../contexts/auth-context';
import useHttp from '../../../../hooks/use-http';
import ConfirmPopUp from '../../../PopUps/ConfirmPopUp/ConfirmPopUp';
import Button from '../../../UI/Input/Button/Button';
import InputRadio from '../../../UI/Input/InputRadio';
import Loader from '../../../UI/Loader/Loader';
import Modal from '../../../UI/Modal/Modal';
import ConflictedSchedulePopUp from '../../PopUps/ConflictedSchedulePopUp/ConflictedSchedulePopUp';
import classes from './AppointmentEditOptionsForm.module.css';

const AppointmentEditOptionsForm = (props) => {
  const { isLoading, sendRequest: sendEditRequest } = useHttp();
  const {
    schedulerState,
    onChangeSchedulerState,
    data,
    title,
    payload,
    enteredDate,
    onCloseEditForm,
    onClose,
  } = props;
  const authCtx = useContext(AuthContext);
  const oneOptRef = useRef();
  const everSinceOtpRef = useRef();
  const allOptRef = useRef();
  const [conflictedSchedulePopUp, setConflictedSchedulePopUp] = useState({
    data: [],
    isOpen: false,
  });
  const [confirmPopUp, setConfirmPopUp] = useState({
    actionType: '',
    isOpen: false,
  });

  const conflictedPopUpHandler = (conflictedSchedule) => {
    setConflictedSchedulePopUp((prevState) => ({
      data: conflictedSchedule ? conflictedSchedule : null,
      isOpen: !prevState.isOpen,
    }));
  };

  const confirmPopUpHandler = (evt) => {
    setConfirmPopUp((prevState) => ({
      actionType: evt.target.innerText,
      isOpen: !prevState.isOpen,
    }));
  };

  const submitHandler = (evt) => {
    evt.preventDefault();
    let once = oneOptRef.current.checked ? 'once' : false;
    let everSince = everSinceOtpRef.current.checked ? 'ever-since' : false;
    let all = allOptRef.current.checked ? 'all' : false;
    let httpMethod =
      title === '반복 일정 취소' ? 'DELETE' : '반복 일정 수정' ? 'PUT' : null;
    let socialStatus;
    if (payload.socialStatus === 'social-public') {
      socialStatus = 'CONFIRMED';
    }

    if (payload.socialStatus === 'social-standby') {
      if (payload.plabRentalOpt) {
        socialStatus = 'NOT_CONFIRMED_CAN_RENT';
      }

      if (!payload.plabRentalOpt) {
        socialStatus = 'NOT_CONFIRMED_CAN_NOT_RENT';
      }
    }

    const adjPrice =
      (payload.productType !== '예약불가' &&
        payload.productType !== '소셜매치') ||
      (payload.productType === '소셜매치' &&
        payload.socialStatus === 'social-standby' &&
        payload.plabRentalOpt)
        ? payload.price
        : null;
    const adjName =
      payload.productType !== '예약가능' && payload.productType !== '예약불가'
        ? payload.consumer
        : null;
    const adjPhoneNum =
      payload.productType !== '소셜매치' &&
      payload.productType !== '예약가능' &&
      payload.productType !== '예약불가'
        ? payload.phoneNum
        : null;
    const adjNote = payload.productType !== '예약가능' ? payload.note : null;

    const updateScheduler = async (res) => {
      const data = await res.json();

      if (res.ok) {
        onClose();
        onCloseEditForm();
        if (schedulerState.viewState === 'month') {
          onChangeSchedulerState({
            type: 'CUR_MONTH',
            date: enteredDate,
          });
        } else if (schedulerState.viewState === 'week') {
          onChangeSchedulerState({
            type: 'CUR_WEEK',
            date: enteredDate,
          });
        }
        return;
      }

      switch (data.code) {
        case 'product_overlapped':
          conflictedPopUpHandler(data.conflictedSchedule);
          break;
        default:
          alert(data.message);
      }
    };

    if (httpMethod === 'DELETE') {
      if (authCtx.userRole === '마스터' && data.productType === '소셜매치') {
        sendEditRequest(
          {
            urlPath: `products/${data.id}/${
              once || everSince || all
            }?cancelStatus=${payload.cancelStatus.optionVal}&cancelType=${
              payload.cancelReason.optionVal
            }`,
            method: httpMethod,
          },
          updateScheduler
        );
      } else {
        sendEditRequest(
          {
            urlPath: `products/${data.id}/${once || everSince || all}`,
            method: httpMethod,
          },
          updateScheduler
        );
      }
    }

    if (httpMethod === 'PUT') {
      sendEditRequest(
        {
          urlPath: `products/${data.id}/${once || everSince || all}`,
          method: httpMethod,
          body: {
            productType: payload.productType,
            socialMatchStatus:
              payload.productType === '소셜매치' ? socialStatus : null,
            time: {
              startTime: payload.time.startTime,
              endTime: payload.time.endTime,
            },
            price: adjPrice,
            consumer: adjName,
            phoneNum: adjPhoneNum,
            note: adjNote,
            zoneId: payload.zoneId,
          },
        },
        updateScheduler
      );
    }
  };

  return (
    <Modal
      title={title}
      onClose={onClose}
      classList={['modalSmall', confirmPopUp.isOpen ? 'hidden' : null]}
    >
      <form className={classes.AppointmentForm} onSubmit={submitHandler}>
        <div className={classes['AppointmentForm__Row']}>
          <InputRadio
            attribute={{
              id: 'once',
              name: 'repeat-option',
              defaultChecked: true,
            }}
            ref={oneOptRef}
          >
            이 일정만
          </InputRadio>
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <InputRadio
            attribute={{
              id: 'ever-since',
              name: 'repeat-option',
            }}
            ref={everSinceOtpRef}
          >
            이 일정 및 향후 일정
          </InputRadio>
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <InputRadio
            attribute={{
              id: 'all',
              name: 'repeat-option',
            }}
            ref={allOptRef}
          >
            모든 일정
          </InputRadio>
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <Button attribute={{ type: 'button' }} onClick={confirmPopUpHandler}>
            {title.slice(3)}
          </Button>
        </div>
      </form>
      {conflictedSchedulePopUp.isOpen && (
        <ConflictedSchedulePopUp
          onClose={conflictedPopUpHandler}
          time={{
            startTime: payload.time.startTime.split('T')[1],
            endTime: payload.time.endTime.split('T')[1],
          }}
          conflictedSchedule={conflictedSchedulePopUp.data}
        />
      )}
      {confirmPopUp.isOpen && (
        <ConfirmPopUp
          actionType={confirmPopUp.actionType}
          onClose={confirmPopUpHandler}
          onSubmit={submitHandler}
        />
      )}
      {isLoading && <Loader />}
    </Modal>
  );
};

export default AppointmentEditOptionsForm;
