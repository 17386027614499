import { useState } from 'react';
import useHttp from '../../../../hooks/use-http';
import ConfirmPopUp from '../../../PopUps/ConfirmPopUp/ConfirmPopUp';
import Button from '../../../UI/Input/Button/Button';
import Select from '../../../UI/Input/Select/Select';
import Loader from '../../../UI/Loader/Loader';
import Modal from '../../../UI/Modal/Modal';
import AppointmentEditOptionsForm from '../AppointmentEditOptionsForm/AppointmentEditOptionsForm';
import FormControlWrap from '../FormControlWrap/FormControlWrap';
import classes from './SelectCancelTypeForm.module.css';

const CANCEL_STATUS = [
  {
    optionVal: 'HIDE',
    optionTxt: '숨기기',
  },
  {
    optionVal: 'CANCEL',
    optionTxt: '취소',
  },
];

const CANCEL_REASON = [
  { type: 'HIDE', optionVal: 'CLIENT', optionTxt: '구장요청' },
  { type: 'HIDE', optionVal: 'EMERGENCY_CLIENT', optionTxt: '긴급구장요청' },
  { type: 'HIDE', optionVal: 'BAD_WEATHER', optionTxt: '기상악화' },
  { type: 'HIDE', optionVal: 'EMERGENCY_WEATHER', optionTxt: '긴급기상악화' },
  { type: 'HIDE', optionVal: 'RESERVATION', optionTxt: '예약실패' },
  { type: 'HIDE', optionVal: 'READY_UNDEFINED', optionTxt: '대기미정' },
  { type: 'HIDE', optionVal: 'MISTAKE', optionTxt: '운영실수' },
  { type: 'CANCEL', optionVal: 'UNDER_PERSON', optionTxt: '인원미달' },
  { type: 'CANCEL', optionVal: 'EMERGENCY_UNDER', optionTxt: '긴급인원미달' },
  { type: 'CANCEL', optionVal: 'PLAB', optionTxt: '운영문제' },
];

const SelectCancelTypeForm = (props) => {
  const {
    onChangeSchedulerState,
    schedulerState,
    data: appointmentData,
    onClose,
  } = props;
  const { isLoading, sendRequest: sendEditRequest } = useHttp();
  const [enteredCancelStatus, setEnteredCancelStatus] = useState(
    CANCEL_STATUS[0]
  );
  const [enteredCancelReason, setEnteredCancelReason] = useState('');
  const [editOptionsFormAction, setEditOptionsFormAction] = useState({
    type: null,
    isOpen: false,
  });
  const [isConfirmPopUpOpen, setIsConfirmPopUpOpen] = useState(false);

  const changeCancelStatusHandler = (evt) => {
    setEnteredCancelReason('');
    setEnteredCancelStatus(() => {
      return CANCEL_STATUS.find((item) => item.optionVal === evt.target.value);
    });
  };
  const changeCancelReasonHandler = (evt) => {
    setEnteredCancelReason(
      CANCEL_REASON.find((item) => item.optionVal === evt.target.value)
    );
  };
  const editOptionsFormOpenHandler = (actionType) => {
    setEditOptionsFormAction((prevState) => ({
      type: actionType ? actionType : null,
      isOpen: !prevState.isOpen,
    }));
  };
  const confirmPopUpHandler = () => {
    setIsConfirmPopUpOpen((prevState) => !prevState);
  };

  const submitHandler = (evt) => {
    evt.preventDefault();
    if (!enteredCancelReason) {
      alert('취소사유를 선택해주세요.');
      if (isConfirmPopUpOpen) confirmPopUpHandler();
      return;
    }
    const updateScheduler = async (res) => {
      const data = await res.json();

      onClose();
      if (res.ok) {
        if (schedulerState.viewState === 'month') {
          onChangeSchedulerState({
            type: 'CUR_MONTH',
            date: new Date(appointmentData.date),
          });
        } else if (schedulerState.viewState === 'week') {
          onChangeSchedulerState({
            type: 'CUR_WEEK',
            date: new Date(appointmentData.date),
          });
        }
        return;
      } else {
        alert(data.message);
      }
    };

    if (!appointmentData.isRepeat) {
      sendEditRequest(
        {
          urlPath: `products/${appointmentData.id}/once?cancelStatus=${enteredCancelStatus.optionVal}&cancelType=${enteredCancelReason.optionVal}`,
          method: 'DELETE',
        },
        updateScheduler
      );
    } else {
      editOptionsFormOpenHandler('반복 일정 취소');
    }
  };

  return (
    <Modal
      title='취소 사유'
      onClose={onClose}
      classList={[
        'modalSmall',
        isConfirmPopUpOpen || editOptionsFormAction.isOpen
          ? 'hidden'
          : ['fullScreen'],
      ]}
    >
      <form className={classes.AppointmentForm}>
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap>
            <label htmlFor={'cancel-status'} className={classes.InputLabel}>
              상태
            </label>
            <Select
              selectType='object'
              attribute={{
                id: 'cancel-status',
                value: enteredCancelStatus.optionVal,
                onChange: changeCancelStatusHandler,
              }}
              options={CANCEL_STATUS}
            />
          </FormControlWrap>
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap>
            <label htmlFor={'cancel-reason'} className={classes.InputLabel}>
              사유
            </label>
            <Select
              selectType='object'
              attribute={{
                id: 'cancel-reason',
                value: enteredCancelReason ? enteredCancelReason.optionVal : '',
                onChange: changeCancelReasonHandler,
              }}
              options={
                enteredCancelStatus.optionVal === 'HIDE'
                  ? CANCEL_REASON.filter((item) => item.type === 'HIDE')
                  : CANCEL_REASON.filter((item) => item.type === 'CANCEL')
              }
              classList={[enteredCancelReason === '' ? 'has-placeholder' : '']}
              placeholder='취소사유'
            />
          </FormControlWrap>
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap>
            <Button
              attribute={{ type: 'button' }}
              classList={['Button--Delete']}
              onClick={onClose}
            >
              취소
            </Button>
            <Button
              attribute={{ type: 'button' }}
              onClick={
                appointmentData.isRepeat ? submitHandler : confirmPopUpHandler
              }
            >
              확인
            </Button>
          </FormControlWrap>
        </div>
      </form>
      {editOptionsFormAction.isOpen && (
        <AppointmentEditOptionsForm
          {...props}
          title={editOptionsFormAction.type}
          enteredDate={new Date(appointmentData.date)}
          payload={
            editOptionsFormAction.type === '반복 일정 수정'
              ? {
                  productType: appointmentData.enteredProductType,
                  socialStatus: appointmentData.enteredSocialStatus,
                  plabRentalOpt: appointmentData.enteredPlabRentalOpt,
                  time: {
                    startTime: appointmentData.enteredStartTime.optionVal,
                    endTime: appointmentData.enteredEndTime.optionVal,
                  },
                  price: appointmentData.enteredPrice,
                  consumer: appointmentData.enteredName,
                  phoneNum: appointmentData.enteredPhoneNum,
                  note: appointmentData.enteredNote,
                  zoneId: appointmentData.enteredStadiumZone,
                }
              : editOptionsFormAction.type === '반복 일정 취소'
              ? {
                  cancelStatus: enteredCancelStatus,
                  cancelReason: enteredCancelReason,
                }
              : null
          }
          onCloseEditForm={onClose}
          onClose={editOptionsFormOpenHandler}
        />
      )}
      {isConfirmPopUpOpen && (
        <ConfirmPopUp
          actionType={'일정 취소'}
          onClose={confirmPopUpHandler}
          onSubmit={submitHandler}
        />
      )}
      {isLoading && <Loader />}
    </Modal>
  );
};

export default SelectCancelTypeForm;
