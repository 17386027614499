import { useContext, useRef, useState } from 'react';
import AuthContext from '../../../../contexts/auth-context';
import useHttp from '../../../../hooks/use-http';
import ConfirmPopUp from '../../../PopUps/ConfirmPopUp/ConfirmPopUp';
import Button from '../../../UI/Input/Button/Button';
import InputRadio from '../../../UI/Input/InputRadio';
import Loader from '../../../UI/Loader/Loader';
import Modal from '../../../UI/Modal/Modal';
import classes from './RefundForm.module.css';

const RefundForm = (props) => {
  const { isLoading, sendRequest: sendRefundRequest } = useHttp();
  const authCtx = useContext(AuthContext);
  const {
    onClose,
    schedulerState,
    onChangeSchedulerState,
    data,
    onCloseEditForm,
  } = props;
  const refundRate100 = useRef();
  const refundRate90 = useRef();
  const refundRate80 = useRef();
  const refundRate70 = useRef();
  const refundRate60 = useRef();
  const refundRate50 = useRef();
  const refundRate0 = useRef();
  const [confirmPopUp, setConfirmPopUp] = useState({
    actionType: '',
    isOpen: false,
  });

  const confirmPopUpHandler = (evt) => {
    setConfirmPopUp((prevState) => ({
      actionType: evt.target.innerText,
      isOpen: !prevState.isOpen,
    }));
  };

  const submitHandler = (evt) => {
    evt.preventDefault();

    const selectedRadio = document.querySelector(
      'input[name="refund-option"]:checked'
    );

    const updateScheduler = async (res) => {
      const resData = await res.json();

      if (res.ok) {
        onClose();
        onCloseEditForm();
        if (schedulerState.viewState === 'month') {
          onChangeSchedulerState({
            type: 'CUR_MONTH',
            date: new Date(data.date),
          });
        } else if (schedulerState.viewState === 'week') {
          onChangeSchedulerState({
            type: 'CUR_WEEK',
            date: new Date(data.date),
          });
        }
        return;
      }
      alert(resData.message);
    };

    sendRefundRequest(
      {
        urlPath: `products/plab-rent-zones/${data.id}/cancel`,
        method: 'PUT',
        body: {
          productId: data.id,
          refundRate: selectedRadio.value,
        },
      },
      updateScheduler
    );
  };

  return (
    <Modal
      title='대관 취소'
      onClose={onClose}
      classList={['modalSmall', confirmPopUp.isOpen ? 'hidden' : null]}
    >
      <form className={classes.AppointmentForm} onSubmit={submitHandler}>
        <div className={classes['AppointmentForm__Row']}>
          <InputRadio
            attribute={{
              id: 'refund-rate-100',
              name: 'refund-option',
              value: '100',
              defaultChecked: true,
            }}
            ref={refundRate100}
          >
            100% 환불
          </InputRadio>
        </div>
        {authCtx.userRole === '마스터' && (
          <>
            <div className={classes['AppointmentForm__Row']}>
              <InputRadio
                attribute={{
                  id: 'refund-rate-90',
                  name: 'refund-option',
                  value: '90',
                }}
                ref={refundRate90}
              >
                90% 환불
              </InputRadio>
            </div>
            <div className={classes['AppointmentForm__Row']}>
              <InputRadio
                attribute={{
                  id: 'refund-rate-80',
                  name: 'refund-option',
                  value: '80',
                }}
                ref={refundRate80}
              >
                80% 환불
              </InputRadio>
            </div>
            <div className={classes['AppointmentForm__Row']}>
              <InputRadio
                attribute={{
                  id: 'refund-rate-70',
                  name: 'refund-option',
                  value: '70',
                }}
                ref={refundRate70}
              >
                70% 환불
              </InputRadio>
            </div>
            <div className={classes['AppointmentForm__Row']}>
              <InputRadio
                attribute={{
                  id: 'refund-rate-60',
                  name: 'refund-option',
                  value: '60',
                }}
                ref={refundRate60}
              >
                60% 환불
              </InputRadio>
            </div>
            <div className={classes['AppointmentForm__Row']}>
              <InputRadio
                attribute={{
                  id: 'refund-rate-50',
                  name: 'refund-option',
                  value: '50',
                }}
                ref={refundRate50}
              >
                50% 환불
              </InputRadio>
            </div>
          </>
        )}
        <div className={classes['AppointmentForm__Row']}>
          <InputRadio
            attribute={{
              id: 'refund-rate-0',
              name: 'refund-option',
              value: '0',
            }}
            ref={refundRate0}
          >
            0% 환불
          </InputRadio>
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <Button attribute={{ type: 'button' }} onClick={confirmPopUpHandler}>
            대관 취소
          </Button>
        </div>
      </form>
      {confirmPopUp.isOpen && (
        <ConfirmPopUp
          actionType={confirmPopUp.actionType}
          onClose={confirmPopUpHandler}
          onSubmit={submitHandler}
        />
      )}
      {isLoading && <Loader />}
    </Modal>
  );
};

export default RefundForm;
